import Pagination from '@mui/material/Pagination';
import SurgirBanner from "components/ui/SurgirBanner/SurgirBanner";
import SurgirCardPromotions from "components/ui/SurgirCardPromotions/SurgirCardPromotions";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { apiGetPromotions } from "service/apiEndPoint";
import styled from "styled-components";
import "./PromotionHistory.scss";
import ModalPromotions from "./components/ModalPromotions/ModalPromotions";

const PromotionHistory: React.FC = () => {

  const [promotions, setPromotions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  const [selectedPage, setSelectedPage] = useState(1);

  const getPromotions = (payload: any) => {
    apiGetPromotions(payload).then((response) => {
      return response.json()
    })
      .then((data) => {
        setPromotions(data.data.result);
        setTotalPages(data.data.meta.totalPages)
        setSelectedPage(page);
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    const payload = {
      page: page,
      perPage: 6
    }
    getPromotions(payload);
  }, [page]);

  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const [visibleModalPromotion, setVisibleModalPromotion] = useState(false);
  const [titleModalPromotion, setTitleModalPromotion] = useState('')
  const [documentsModalPromotion, setDocumentsPromotion] = useState([])

  const handleModalPromotion = () => {
    setVisibleModalPromotion(false);
  };


  const breakpoints = {
    small_max: '767px',
  };


  const PaginationStyled = styled(Pagination)`
    margin: 48px auto;
    display: flex;
    justify-content: end;

    & .MuiPagination-ul li:first-child button::after {
      content: "Anterior";
      margin-left: 8px;
      position: relative;
      top: 35%;
      transform: translateY(-50%);
    }

    & .MuiPagination-ul li:last-child button::before {
      content: "Siguiente";
      margin-right: 8px;
      position: relative;
      top: 35%;
      transform: translateY(-50%);
    }

    @media (max-width: ${breakpoints.small_max}) {
      justify-content: center;

      & .MuiPagination-ul li:first-child button::after {
        content: none;
      }
  
      & .MuiPagination-ul li:last-child button::before {
        content: none;
      }
    }
  `;


  return (
    <div>

      <SurgirBanner
        title={'¡Participa de todas nuestras promociones!'}
        description={'Conoce el detalle de cada promoción y gana con SURGIR.'}
        showButton={false}
        image={'hero-promotions-campana-escolar.png'}
        showFooter={false}
        backgroundColor={'#deedf2'}
        altImgBanner={'Alt_ Haz crecer tu negocio y el futuro de tus hijos Campaña Escolar Escolares Negocio Crédito Préstamo Abastece Enero Febrero SURGIR Banner Promociones'}
      />

      <Container>
        <div className="body-promotions">
          <div className="title-promotions">
            <span>Encuentra todas nuestras promociones</span>
          </div>
          <div className="body-cards-promotions">
            {
              promotions.map((card: any, index) => {
                return (
                  <SurgirCardPromotions
                    title={card.title}
                    dateStart={card.dateStart}
                    dateEnd={card.dateEnd}
                    image={card.photoLink}
                    alt={card.alt}
                    onClick={() => {
                      setVisibleModalPromotion(true)
                      setTitleModalPromotion(card.title)
                      setDocumentsPromotion(card.documents)
                    }}
                  />
                )
              })
            }
          </div>
          <div className="pagination-promotions">
            <PaginationStyled
              count={totalPages}
              onChange={handlePageChange}
              page={selectedPage}
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#4C98B3',
                  fontFamily: '"Str-Headline-Bold", sans-serif',
                  fontSize: '16px'
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#3291B8',
                  color: '#FFFFFF',
                },

                '& .MuiPaginationItem-root.Mui-disabled': {
                  color: '#222222',

                },
              }}

            />

          </div>
        </div>
      </Container>


      <ModalPromotions
        title={titleModalPromotion}
        show={visibleModalPromotion}
        handleClose={handleModalPromotion}
        documents={documentsModalPromotion}
      />

    </div>
  );
}

export default PromotionHistory