import React from "react";
import { Card, Col, Row} from "react-bootstrap";
import SurgirItemListWithIcon from "components/ui/SurgirItemListWithIcon/SurgirItemListWithIcon";
import SurgirListIcon from "components/ui/SurgirListIcon/SurgirListIcon";
import "./Transparency.scss";
import { ReactComponent as DownloadIcon } from './../../assets/images/Icons_descargar.svg'
import DownloadIconTab from '../../assets/images/Icons_descargar.svg'
import SurgirTabs from "components/ui/SurgirTabs/SurgirTabs";
import { createLink } from "utils/urls";

const Chevron = 'images/transparency/transparency-chevron-right.svg'

const TABS_TRANSPARENCY = [
  {
    title: 'Procedimientos',
    body: (
      <>
      <Row>
        <Col md={4}>
          <p className="Columna">
            Procedimiento para Pagos Anticipados y Adelanto de Cuotas
          </p>
        </Col>
        <Col md={8}>
          <p>
            Los usuarios tienen derecho a efectuar pagos por encima de la
            cuota exigible en el periodo (pago del mes), y en el caso de
            créditos bajo el sistema de cuotas, se catalogan como:
          </p>
          <span className="subtitulos">1. Pago anticipado: </span>
          <br />
          Monto destinado al pago del capital del crédito, generando la
          reducción de los intereses, comisiones y gastos derivados de las
          cláusulas contractuales al día del pago. Los pagos mayores a dos
          (2) cuotas (incluida la exigible en el periodo), se consideran
          pagos anticipados, debiendo elegir entre:
          <br />   <br />
          <SurgirListIcon icon={Chevron} name={"Reducción del monto de la cuota manteniendo el plazo original del crédito; o,"} />
          <br />
          <SurgirListIcon icon={Chevron} name={"Reducción del número de cuotas con la consecuente reducción del plazo de crédito."} />
          <br />

          Esta elección debe realizarse el mismo día que se efectúa el pago, debiendo llenar el Formato denominado <span className="parrafo-bold-decoration">“SOLICITUD DE PAGO ANTICIPADO Y ADELANTO DE CUOTA”</span>, establecido en el punto 4. De no contar con la instrucción, <span className="parrafo-bold">SURGIR</span> procederá con la reducción del número de cuotas, dentro de los quince (15) días de realizado el pago,
          <br /><br />
          En cualquiera de los casos y a tu solicitud,<span className="parrafo-bold"> SURGIR </span>entregará el cronograma de pagos modificado, considerando el pago realizado, dentro de los siete (7) días calendarios posteriores a la solicitud, estando a tu disposición en nuestras oficinas por medio físico o coordinando con tu asesor de negocios SURGIR para realizar su emisión y envío por canal digital.
          <br />
          <br />
          <span className="subtitulos">2. Adelanto de cuotas: </span>
          <p>
            Monto destinado al pago de las cuotas inmediatamente posteriores
            a la exigible en el periodo, sin la reducción de los intereses,
            comisiones y gastos derivados de las cláusulas contractuales.
            <br />
            Cuando se realice un pago menor o igual a dos (2) cuotas
            (incluye aquella exigible en el período) y no hayas manifestado
            tu elección, se considerará como adelanto de cuotas, salvo que
            se solicite expresamente que aplique como pago anticipado.
          </p>
          <br />
          <span className="subtitulos">3. Canales de Atención:</span>
          <br />


          <br /><p className="subtitulos">a. Adelanto de cuotas </p>

          <SurgirListIcon icon={Chevron} name={" Globokas - Agentes de Cobros Kasnet"} />
          <SurgirListIcon icon={Chevron} name={"BANBIF: agencias / ventanillas; plataforma web y App Móvil."} />
          <SurgirListIcon icon={Chevron} name={"BCP: agencias / ventanillas; agente corresponsal; plataforma web y App Móvil."} />
          <SurgirListIcon icon={Chevron} name={" Billetera Digital: YAPE (*)"} />
          <SurgirListIcon icon={Chevron} name={"Interbank: agente corresponsal; plataforma web y App Móvil."} />
          <SurgirListIcon icon={Chevron} name={"Transferencia o Deposito a Cuenta de SURGIR en el BCP."} />

          <span>(*)</span><span className="parrafo-cursiva">El cliente podrá realizar el pago en, siempre que el monto no exceda la cantidad máxima de transacción que recibe el canal.</span>

          <br />
          <br />
          <p className="subtitulos">b. Pagos Anticipados </p>

          <SurgirListIcon icon={Chevron} name={"Transferencia o Depósito a Cuenta de SURGIR en el BCP."} />
          <br />
          <br />
          <span className="subtitulos">4. Formato de Solicitud de Pagos Anticipados y Adelanto de Cuotas</span>

          <Row style={{ marginTop: 20 }}>
            <div className="card-style-transparence"  >
              <div style={{ width: "100%" }}>
                <span className="parrafo">
                  Solicitud de Pago Anticipado y Adelanto de Cuota
                </span>
              </div>
              <div style={{ width: "40%" }}>

                <SurgirItemListWithIcon
                  icon={DownloadIconTab} 
                  document={process.env.REACT_APP_SOLICITUD_DE_PAGO_ANTICIPADO}
                  name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} 
                  downloadName={"Terminos"}
                ></SurgirItemListWithIcon>
              </div>
            </div>
          </Row>
          <br />
          <hr />
          <br />
        </Col>
      </Row>
      <Row style={{ padding: 0 }}>
        <Col md={4}>
          <p className="Columna">
            Procedimiento para la solicitud de Resolución de Contrato
          </p>
        </Col>
        <Col md={8}>
          <p>
            La resolución de contrato, sólo procede en caso exista
            modificación (es) posterior (es) a la activación de su crédito,
            y en caso se estime que dicha modificación genera perjuicio al
            Titular del Crédito (en adelante “EL CLIENTE”).
          </p>
          <p>
            Asimismo, estas modificaciones no deben ser originadas por
            cumplimiento de obligaciones normativas, es decir, si las
            modificaciones son generadas por cambios en la norma EL CLIENTE
            no tiene la posibilidad de solicitar resolución de contrato.
          </p>
          <p>
            EL CLIENTE deberá informar a través de una Carta Formal Simple a
            SANTANDER FINANCIAMIENTOS S.A. (en adelante “SURGIR”) y cancelar
            previamente el total del crédito según la liquidación que
            efectúe SURGIR dentro del plazo de preaviso, el cual no podrá
            ser menor a los 45 (cuarenta y cinco) días calendarios.
          </p>
          <p></p>
          <p></p>
          <p></p>
          <br />
        </Col>
      </Row>
      <Row style={{ padding: 0 }}>
        <Col md={4}>
          <br />
          <br />
          <br />
          <p className="Columna">
            Procedimiento para la Remisión Periódica de Información
          </p>
        </Col>
        <Col md={8}>
          <hr />
          <br />
          <Row style={{ marginTop: 20 }}>
            <div className="card-style-transparence"  >
              <div style={{ width: "100%" }}>
                <span className="parrafo">
                  Procedimiento para la Remisión Periódica de Información
                </span>
              </div>
              <div style={{ width: "40%" }}>
                <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_PROCEDIMIENTO_REMISION_PERIODICA}
                  name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
              </div>
            </div>
          </Row>
          <p></p>
          <Row style={{ marginTop: 20 }}>
            <div className="card-style-transparence"  >
              <div style={{ width: "100%" }}>
                <span className="parrafo">
                  Solicitud de Remisión “Sírvanse Ejecutar”
                </span>
              </div>
              <div style={{ width: "40%" }}>
                <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_SOLICITUD_DE_REMISION}
                  name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
              </div>
            </div>
          </Row>
          <p></p>
          <p></p>
          <br />
        </Col>
      </Row>
      </>
      
          
    )
  },
  {
    title: 'Reclamos y Requerimientos',
    body: (
      <>
        <Row>
          <Col md={4}>
            <p className="Columna">Procedimiento de Atención de Reclamos</p>
          </Col>
          <Col md={8}>
            <span className="subtitulos">¿Qué es un reclamo?</span>
            <p>
              Es una comunicación presentada por el usuario o tercero a nombre
              del usuario, a través de los diferentes canales de atención
              disponibles, expresando su insatisfacción con la operación,
              servicio y/o producto recibido o por incumplimiento de las
              obligaciones contempladas en los contratos o norma vigente, o
              manifestando la presunta afectación de su legítimo interés.
            </p>
            <hr />
            <span className="subtitulos">
              Canales de atención disponibles
            </span>
            <p>
              1. Presencial: en nuestras Oficinas.


              <br />
              2. Vía internet: A través de nuestra Página Web <a style={{ textDecoration: "none", color: "#257fa4" }}
                target="_blank"
                href="/reclamos-y-requerimientos "
                rel="noopener noreferrer" > www.surgir.com.pe </a>
              <br />
              3. Vía Telefónica: (01) 480 0123

            </p>
            <hr />
            <span className="subtitulos">
              ¿Qué información se les solicitará al momento de presentar un
              reclamo?
            </span>
            <br />{" "}
            <span style={{ color: "#ec0000", fontStyle: "bold" }}>- </span>{" "}
            Nombres y apellidos
            <br />
            <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}
            Tipo y número de documento de identidad
            <br />
            <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}
            Dirección domiciliaria y correo electrónico
            <br />
            <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}
            Número de teléfono de contacto
            <br />
            <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}
            Tipo de Producto
            <br />
            <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}
            Motivos y detalle del reclamo
            <br />
            <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}
            Forma de envió de respuesta a su reclamo (Dirección o correo
            electrónico)
            <br />
            <hr />
            <span className="subtitulos">
              ¿En cuánto tiempo daremos respuesta?
            </span>
            <p>Atenderemos su reclamo en un plazo máximo de <b> quince (15) días hábiles</b>, contados a partir del día siguiente hábil de su presentación.</p>
            <p>Este plazo podrá extenderse, siempre que la naturaleza del reclamo requiera el pronunciamiento previo de un tercero.</p>
            <p>La ampliación será comunicada, explicando las razones, además de precisar el nuevo para estimado de respuesta. </p>
            <hr />
            <span className="subtitulos">
              ¿Qué puedo hacer si no estoy conforme con la respuesta al
              reclamo?
            </span>
            <p className="section-information__tabs__list-no-order">
              Puede acudir a:
              <ul>
                <li >INDECOPI{" "}
                  <a
                    style={{ textDecoration: "none", color: "#257fa4" }}
                    target="_blank"
                    href="https://www.gob.pe/indecopi"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    (www.indecopi.gob.pe)
                  </a> ; o</li>
                <li>Plataforma de Atención al Usuario (PAU) de la Superintendencia de Banca, Seguros y AFP, a la línea gratuita 0-800-10840 o ingresando a www.sbs.gob.pe/contactenos
                </li>
              </ul>
            </p>

            <p></p>
            <p></p>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <p className="Columna">
              Procedimiento de Atención de Requerimientos
            </p>
          </Col>
          <Col md={8}>
            <span className="subtitulos">¿Qué es un requerimiento?</span>
            <p>
              Es una comunicación presentada por un usuario o tercero a nombre
              del usuario, mediante la cual se busca una acción por parte de
              la empresa. Los requerimientos comprenden la atención de:
              <br />
              <span className="subtitulos"> A. Consultas</span>
              <br />
              Comunicaciones por las que se busca que SURGIR proporcione determinada información ante una situación de incertidumbre, desconocimiento o duda relacionada a la operación, producto o servicio ofrecido o contratado con SURGIR.
              <br />
              <br />
              <span className="subtitulos">B. Solicitudes </span>
              <br />
              Solicitud de entrega o puesta a disposición de información que SURGIR posee sobre la relación que mantiene o mantuvo con los usuarios.
              <br />
              <br />
              <span className="subtitulos"> C. Otras solicitudes </span>
              <br />
              Distintas a las indicadas en el punto B y que no se encuentre en lo siguientes supuestos:
              <br />
              i. Aspectos relacionados a atención de reclamos.
              <br />
              ii. Aspectos propios de la ejecución del contrato.
              <br />
              iii. Contratación de nuevos productos o servicios.
              <hr />
            </p>
            <span className="subtitulos">
              Canales de atención disponibles
            </span>
            <p>
              1. Presencial: en nuestras Oficinas.
              <br />
              2. Vía internet: A través de nuestra Página Web <a style={{ textDecoration: "none", color: "#257fa4" }}
                target="_blank"
                href="/reclamos-y-requerimientos "
                rel="noopener noreferrer" > www.surgir.com.pe </a>
              <br />
              3. Vía Telefónica: (01) 480 0123
            </p>
            <hr />
            <span className="subtitulos">
              ¿Qué información se les solicitará al momento de presentar un
              requerimiento?
            </span>
            <p>
              <span style={{ color: "#ec0000", fontStyle: "bold" }}>- </span>{" "}
              Nombres y apellidos
              <br />
              <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                -{" "}
              </span>{" "}
              Tipo y número de documento de identidad
              <br />
              <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                -{" "}
              </span>{" "}
              Dirección domiciliaria y correo electrónico
              <br />
              <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                -{" "}
              </span>{" "}
              Número de teléfono de contacto
              <br />
              <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                -{" "}
              </span>{" "}
              Detalle de lo requerido
              <br />
              <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                -{" "}
              </span>{" "}
              Forma de envió de respuesta: Dirección o correo electrónico
            </p>
            <hr />
            <span className="subtitulos">
              ¿En cuánto tiempo daremos respuesta?
            </span>
            <p>
              Atenderemos su requerimiento en un plazo máximo de <b> quince (15) días hábiles</b>, contados a partir del día siguiente hábil de su presentación.

              <br />
              Este plazo podrá extenderse, siempre que la naturaleza del requerimiento requiera el pronunciamiento previo de un tercero.
              <br />
              La ampliación será comunicada, explicando las razones, además de precisar el nuevo para estimado de respuesta.
            </p>
            <br />
            <p></p>
            <p></p>
          </Col>
        </Row>
      </>
    )
  },
  {
    title: 'Reporte de Reclamos',
    body: (
      <>
        <Row>
          <Col md={4}>
            <p className="Columna">
              Reporte de Reclamos

            </p>
          </Col>
          <Col md={8}>

            <Row style={{ marginTop: 10 }}>
              <div className="card-style-transparence"  >
                <div style={{ width: "100%" }}>
                  <span className="parrafo">
                    Reporte de reclamos - Noviembre 2024	
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_REPORTE_RECLAMOS_2024_1}
                    name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <div className="card-style-transparence"  >
                <div style={{ width: "100%" }}>
                  <span className="parrafo">
                    Reporte de reclamos - Octubre 2024
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_REPORTE_RECLAMOS_2024_2}
                    name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <div className="card-style-transparence"  >
                <div style={{ width: "100%" }}>
                  <span className="parrafo">
                    Reporte de reclamos - Setiembre 2024
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_REPORTE_RECLAMOS_2024_3}
                    name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <div className="card-style-transparence"  >
                <div style={{ width: "100%" }}>
                  <span className="parrafo">
                    Reporte de reclamos - Agosto 2024
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_REPORTE_RECLAMOS_2024_4}
                    name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <div className="card-style-transparence"  >
                <div style={{ width: "100%" }}>
                  <span className="parrafo">
                    Reporte de reclamos - Julio 2024
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_REPORTE_RECLAMOS_2024_5}
                    name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <div className="card-style-transparence"  >
                <div style={{ width: "100%" }}>
                  <span className="parrafo">
                    Reporte de reclamos - Junio 2024
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_REPORTE_RECLAMOS_2024_6}
                    name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <div className="card-style-transparence"  >
                <div style={{ width: "100%" }}>
                  <span className="parrafo">
                    Reporte de reclamos - Mayo 2024
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_REPORTE_RECLAMOS_2024_7}
                    name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <div className="card-style-transparence"  >
                <div style={{ width: "100%" }}>
                  <span className="parrafo">
                    Reporte de reclamos - Abril 2024
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  <SurgirItemListWithIcon icon={DownloadIconTab} document={process.env.REACT_APP_REPORTE_RECLAMOS_2024_8}
                    name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} downloadName={"Terminos"} />
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </>
    )
  },
  {
    title: 'Atención preferente',
    body: (
      <Row style={{ padding: 0 }}>
        <Col md={4}>
          <p className="Columna">Ley de Atención Preferente</p>
        </Col>
        <Col md={8}>
          <p className="subtitulos">LEY N° 28683</p>{" "}
          <p className="subtitulos">
            LEY QUE MODIFICA LA LEY Nº 27408, LEY QUE ESTABLECE LA ATENCION
            PREFERENTE A LAS MUJERES EMBARAZADAS, LAS NIÑAS, NIÑOS, LOS
            ADULTOS MAYORES, EN LUGARES DE ATENCION AL PUBLICO
          </p>
          <br />
          <p className="subtitulos">Artículo 1º.- Modificación</p>Modificase
          el artículo único de la Ley Nº 27408. Ley que establece la
          atención preferente a las mujeres embarazadas, las niñas, niños,
          los adultos mayores, en lugares de atención al público, el que
          queda redactado con el siguiente texto:
          <br />
          <br />
          <p className="subtitulos">“Artículo 1º.- Objeto de la Ley</p>
          Dispónese que en los lugares de atención al público las mujeres
          embarazadas, las niñas, niños, las personas adultas mayores y con
          discapacidad, deben ser atendidas y atendidos preferentemente.
          Asimismo, los servicios y establecimientos de uso público de
          carácter estatal o privado deben implementar medidas para
          facilitar el uso y/o acceso adecuado para las mismas”.
          <br />
          <br />
          <p className="subtitulos">Artículo 2º.- Incorporación</p>
          Incorpórense los artículos 2º, 3º, 4º, 5º y 6º a la Ley Nº 27408,
          Ley que establece la atención preferente a las mujeres
          embarazadas, las niñas, niños, los adultos mayores, en lugares de
          atención al público, los que quedan redactados con el siguiente
          texto:
          <br />
          <br />
          <p className="subtitulos">“Artículo 2º.- Obligaciones</p>Las
          entidades públicas y privadas de uso público deben:
          <br />
          1. Consignar en lugar visible de fácil acceso y con caracteres
          legibles el texto de la presente Ley.
          <br />
          2. Emitir directivas para el adecuado cumplimiento de la Ley, las
          que deben ser publicadas en su portal electrónico.
          <br />
          3. Adecuar su infraestructura arquitectónica cuando corresponda.
          <br />
          4. Capacitar al personal de atención al público.
          <br />
          5. Exonerar de turnos o de cualquier otro mecanismo de espera a
          los beneficiarios de la presente Ley.
          <br />
          6. Implementar un mecanismo de presentación de quejas contra
          funcionarios públicos, servidores o empleados, que incumplan su
          obligación de otorgar atención preferente. Así como llevar un
          registro de control de las sanciones que impongan, las cuales
          deben poner en conocimiento de la municipalidad correspondiente.
          <br />
          7. Otras que establezca el reglamento.”
          <br />
          <br />
          <p className="subtitulos">Artículo 3º.- Multa</p>Establécese la
          sanción de multa por incumplimiento a la Ley, la cual no excederá
          el 30% de la Unidad Impositiva Tributaria (UIT), y se aplica
          atendiendo a la magnitud de la infracción y con criterio de
          gradualidad. El dinero recaudado por este concepto se destina a
          financiar programas de promoción, educación y difusión de la
          presente Ley.
          <br />
          <br />
          <p className="subtitulos">Artículo 4°. – Infracciones</p>
          Infracciones a la Ley:
          <br />
          <br />
          1. No brindar atención preferente a las mujeres embarazadas,
          niñas, niños, personas adultas mayores y con discapacidad, en los
          lugares de atención al público.
          <br />
          2. Omitir consignar en lugar visible, de fácil acceso y con
          caracteres legibles el texto de la presente Ley.
          <br />
          3. No emitir directivas para el adecuado cumplimiento de la Ley
          y/u omitir publicarlas en su portal electrónico.
          <br />
          4. No adecuar su infraestructura arquitectónica cuando
          corresponda.
          <br />
          5. No implementar un mecanismo de presentación de quejas contra
          funcionarios públicos, servidores o empleados, que incumplan su
          obligación de otorgar atención preferente.
          <br />
          6. No llevar un registro de control de las sanciones que se
          impongan.
          <br />
          7. No exonerar de turnos o cualquier otro mecanismo de espera a
          los beneficiarios de la presente Ley.
          <br />
          8. Otras que establezca el reglamento.
          <br />
          <br />
          <p className="subtitulos">Artículo 5º.- Entidad Competente</p>La
          municipalidad se encarga de aplicar las multas en el ámbito de su
          jurisdicción comunicando de su imposición y pago a la Defensoría
          Municipal del Niño y Adolescente (DEMUNA), Oficina Municipal de
          las Personas con Discapacidad (OMAPED) y oficinas de servicio
          social.
          <br />
          <br />
          <p className="subtitulos">
            Artículo 6º.- Licencias de Funcionamiento
          </p>
          Las municipalidades dictan las disposiciones necesarias para que
          previo al otorgamiento de Licencia de Funcionamiento de los
          establecimientos en los que se brinde atención al público se
          verifique el cumplimiento de la presente ley.
          <br />
          <br />
          <p className="subtitulos">
            DISPOSICIONES COMPLEMENTARIAS Y FINALES
          </p>
          PRIMERA. - Las municipalidades provinciales y distritales en el
          término de treinta días (30) contados desde la vigencia de la Ley
          dictan las disposiciones que sean necesarias para el cumplimiento
          de la presente Ley, las que debe publicar conforme a lo
          establecido en el numeral 1 del artículo 2º.
          <br />
          SEGUNDO. - Deróguense o déjense sin efecto las normas legales que
          se opongan a lo dispuesto en la presente Ley.
          <br />
          TERCERA. - La presente Ley entra en vigencia al día siguiente de
          su publicación. Comuníquese al señor presidente de la República
          para su promulgación.
          <br />
          <br />
          En Lima, a los diecisiete días del mes de febrero de dos mil seis.
          <br />
          <br />
          <br />
        </Col>
      </Row>
    )
  },
  {
    title: 'Educación financiera',
    body: (
      <Row style={{ padding: 0 }}>
        <Col md={4}>
          <p className="Columna">Educación financiera</p>
        </Col>
        <Col md={8}>
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>
                Tema 1
              </Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Tema_1"
                  onClick={() => createLink(process.env.REACT_APP_EDUCACION_FINANCIERA_TEMA_1 ?? "", "_blank")} 
                >
                  {" "}
                  <DownloadIcon /> Descargar{" "}
                </a>
              </Col>
            </Row>
          </Card>
          <br />

        </Col>
      </Row>
    )
  },
  {
    title: 'Avisos importantes',
    body: (
      <Row style={{ padding: 0 }}>
        <Col md={4}>
          <p className="Columna">Avisos importantes</p>
        </Col>
        <Col md={8}>
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>
                Código de Buenas Prácticas y Autoevaluación
              </Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Aviso_1"
                  onClick={() => createLink(process.env.REACT_APP_DOCS_CODIGO_BUENAS_PRACTICAS ?? "", "_blank")} 
                >
                  {" "}
                  <DownloadIcon /> Descargar{" "}
                </a>
              </Col>
            </Row>
            
          </Card>
          <br />
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>
                Certificado de Autorización de Funcionamiento
              </Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Certificado_de_autorización_de_funcionamiento"
                  onClick={() => createLink(process.env.REACT_APP_DOCS_CERTIFICADO_FUNCIONAMIENTO ?? "", "_blank")} 
                >
                  {" "}
                  <DownloadIcon /> Descargar{" "}
                </a>
              </Col>
            </Row>
            
          </Card>
          <br />
        </Col>
      </Row>
    )
  }
]

const Transparency: React.FC = () => {
  
  return (
    <div className="parrafo">
      <div className="fq-header wrapper" style={{ paddingTop: 93, marginBottom: 30 }}>
        <span className="fq-title">Transparencia </span>
      </div>

      <SurgirTabs 
        hash={'#Reclamos-y-Requerimientos'}
        tabHash={1} 
        tabs={TABS_TRANSPARENCY} 
      />
      
    </div>
  );
}

export default Transparency
