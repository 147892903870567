import { useMediaQuery, width } from '@mui/system';
import SurgirAccordion from 'components/ui/SurgirAccordion/SurgirAccordion';
import SurgirBanner from 'components/ui/SurgirBanner/SurgirBanner';
import SurgirButton from 'components/ui/SurgirButton/SurgirButton';
import SurgirCarousel from 'components/ui/SurgirCarousel/SurgirCarousel';
import SurgirDownloadIcon from 'components/ui/SurgirDownloadIcon/SurgirDownloadIcon';
import SurgirPrincipalTitle from 'components/ui/SurgirPrincipalTitle/SurgirPrincipalTitle';
import SurgirSlideSantander from 'components/ui/SurgirSlideSantander/SurgirSlideSantander';
import SurgirTabs from 'components/ui/SurgirTabs/SurgirTabs';
import LinkRoutes from 'constants/LinkRoutes';
import React, { useState } from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { goTopTab } from 'utils/functions';
import Card2 from '../../assets/images/savings-account/dpff-card.png';
import Benefits from '../../assets/images/savings-account/benefits.png';
import Card1 from '../../assets/images/savings-account/dpf-card.png';
import './SavingsAccount.scss';

const SavingsAccount: React.FC = () => {
  const [tabName, setTabName] = useState('Requisitos')
  const location = useLocation();

  const [faqVisible, setFaqVisible] = useState(false)

  const redirectTabRequisitos = () => {
    if (location.hash == '#tabs') {
      setTabName('Requisitos')
    }
  }

  const goTabDocument = () => {
    goTopTab('tabs');
    setTabName('Documentos');
  }

  
  const benefitsSavingsAccount = [
    {
      icon: 'santander.svg',
      title: 'Seguridad y respaldo',
      description: 'Supervisados por la SBS y con el respaldo de Santander, tus ahorros estarán seguros.',
      link: true,
      textLink: 'Santander',
      hrefLink: 'https://www.santander.com.pe/'
    },
    {
      icon: 'hands-red.svg',
      title: 'Atención personalizada',
      description: 'Nuestros Ejecutivos de Cuenta te brindarán servicio personalizado y asesoría financiera.'
    },
    {
      icon: 'clock-check.svg',
      title: 'Apertura rápida y sencilla',
      description: 'Ábrelo en donde estés con la apertura de tu Depósito a Plazo Fijo.'
    },
    {
      icon: 'financing-carousel.svg',
      title: 'Gana intereses diarios',
      description: 'Recibe los intereses ganados de forma diaria.'
    },
    {
      icon: 'security-verified.svg',
      title: 'Confidencialidad',
      description: 'Nuestras políticas protegen tu información en todo momento.'
    },
  ]

  const frequentQuestionsPART1 = [
    {
      title: '¿Qué es una Cuenta de Ahorros?',
      body: <p>
      Es una cuenta de ahorros que te permite disponer tu dinero en el momento que lo necesites.
      </p>
    },
    {
      title: '¿Cuáles son las ventajas de mi Cuenta de Ahorros?',
      body: <ul>
      <li>Te pagamos diariamente intereses para que puedas disponer de tu dinero y usarlo cuando quieras.</li>
      <li>No cobramos comisión por transferencia a otros bancos.</li>
    </ul>
    },
    {
      title: '¿Qué necesito para abrir mi Cuenta de Ahorros?',
      body: <p>
      Solo necesitas presentar tu Documento de Identidad, el depósito de apertura desde S/ 0.00.
    </p>
    },
  ];

  const frequentQuestionsPART2 = [
    {
      title: '¿Tengo operaciones libres de costo con la Cuenta de Ahorros?',
      body: <p>
      Sí, nuestra cuenta te permite realizar depósitos de manera ilimitada, así como transferencias entre cuentas SURGIR a costo cero.
    </p>
    },
    {
      title: '¿Cuántas operaciones de retiro tengo libres en las Oficinas de SURGIR?',
      body:  <p>
      Podrás realizar hasta 2 retiros libres de costo en nuestras oficinas con tu Cuenta de Ahorros. Además, con una carta de instrucción podrás realizar transferencias de dinero hacia otra cuenta y no te cobramos comisión. Para más información contactarse con su Ejecutivo de Cuenta.
     </p>
    },
    {
      title: '¿Cómo se calcula el interés en mi Cuenta de Ahorros?',
      body: <p>
      El interés se calcula y se acredita en forma diaria. Es decir, se capitaliza todos los días.
      </p>
    },
    {
      title: '¿Qué necesito para abrir mi Depósito a Plazo Fijo en SURGIR?',
      body: <p>
      Solo necesitas presentar tu Documento de Identidad y el depósito del monto mínimo de apertura. Conoce más <a href="#tabs" onClick={redirectTabRequisitos} >aquí.</a>
    </p>
    },
    {
      title: '¿Cuál es el saldo mínimo de equilibrio?',
      body: <p>
      Saldo mínimo de equilibrio es igual al monto mínimo de apertura.
      </p>
    },
    {
      title: '¿Cuál es el monto mínimo de apertura?',
      body:  <p>El monto mínimo de apertura es S/ 0 (cero soles).</p>
    },
    {
      title: '¿La Cuenta de Ahorros se encuentra cubierta por el Fondo de Seguro Depósito?',
      body: <p>
      SURGIR deberá efectuar el pago de las primas que fije la Superintendencia de Banca, Seguros y AFP (SBS), durante veinticuatro (24) meses sucesivos para que sus operaciones se encuentren respaldadas por el Fondo y dar cumplimiento a lo dispuesto en el Artículo N° 3 “Nuevos Miembros” de la Resolución SBS N° 0657 - 99 y sus modificatorias. Por lo tanto, los Productos Pasivos en SURGIR estarán protegidos por el Fondo de seguro de depósito dentro del periodo de veinticuatro (24) meses de acuerdo con lo establecido en la normativa vigente aplicable. Más información en <a href="https://www.surgir.com.pe/">www.surgir.com.pe</a> o en <a href="https://fsd.org.pe/">www.fsd.org.pe</a>.
      </p>
    },
    {
      title: '¿Qué es la TREA?',
      body: <p>La TREA es la Tasa de Rendimiento Efectiva Anual y te permite saber cuánto ganarás efectivamente por el dinero que deposites al vencimiento del plazo, considerando todos los cargos por comisiones y gastos, bajo el supuesto de cumplimiento de todas las condiciones pactadas.</p>
    },
    {
      title: '¿Cuál es el procedimiento aplicable en el caso de fallecimiento del titular de la cuenta?',
      body: <div>
        <div>
                          <p><span>Por Sucesión Testada:</span></p>
                          <ul>
                            <li>Partida de defunción en original o copia.</li>
                            <li>Copia legalizada notarial del testamento.</li>
                            <li>Copia literal original del Testamento inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                            <li>Copia del documento de identidad de cada solicitante. En caso de menores de edad, deberá proporcionar la Partida de nacimiento y el documento de identidad.</li>
                          </ul>
                        </div>

                        <div>
                          <p><span>Por Sucesión Intestada:</span></p>
                          <p><span>Notarial:</span></p>
                          <ul>
                            <li>Copia legalizada del Acta de la Sucesión Intestada (que declara herederos del causante) o la protocolización notarial de Declaratoria de Herederos.</li>
                            <li>Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                            <li>Copia del documento de identidad de cada solicitante. En caso de menores de edad, deberá proporcionar la Partida de nacimiento y el documento de identidad.</li>
                          </ul>

                          <p><span>Judicial:</span></p>
                          <ul>
                            <li>Copia certificada por el Poder Judicial de la Resolución de Sucesión Intestada emitida por el juzgado respectivo (que declare los herederos del causante).</li>
                            <li>Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                          </ul>
                        </div>

                        <div>
                          <p>
                            <span>En el supuesto de que exista heredero menor de edad existen dos opciones presentar una de ellas dependiendo su caso:</span>
                          </p>
                          <ul>
                            <li>Copia certificada por el Poder Judicial de la Resolución de División y Partición y de la Resolución que la declare consentida.</li>
                            <li>Copia certificada por el Poder Judicial de la Resolución Judicial que autoriza a disponer los fondos del menor, y de la Resolución que la declare consentida, para los casos que se requiera disponer de los fondos que le corresponda al menor.</li>
                          </ul>
                        </div>

                        <div>
                          <p>
                            <span>En el caso que uno de los herederos declarados haya fallecido:</span>
                          </p>
                          <ul>
                            <li>Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                          </ul>
                        </div>

                        <div>
                          <p>
                            <span>En el supuesto que uno de los solicitantes actúe mediante poder:</span>
                          </p>
                          <ul>
                            <li>Copia literal original de otorgamiento de poder o vigencia de poder, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                          </ul>
                        </div>

                        <div>
                          <p>
                            <span>En el caso que el fallecido era casado con separación de bienes:</span>
                          </p>
                          <ul>
                            <li>Copia literal original de la separación de patrimonios si la hubiese, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                          </ul>
                        </div>
      </div>
    },
    {
      title: '¿Cuál es el procedimiento de cancelación de la cuenta?',
      body: <div>
        <p>
                        El cliente deberá solicitar la cancelación de su cuenta contactándose con su Ejecutivo de Cuenta, acercándose a la Oficina Especial Compartida o comunicándose a través del buzón atencionahorros@surgir.com.pe, presentando los siguientes documentos:
                      </p>

                      <ul>
                        <li>La <span role="button" className="span-surgir" onClick={goTabDocument}>Carta de Instrucción</span> llenada dirigida a la Financiera y firmada por los titulares de la cuenta.</li>
                        <li>Adjuntar la copia de DOI (documento oficial de identidad) del (los) titular(es).</li>
                      </ul>

                      <p>
                        <span>Importante:</span> Si deseas transferir tu dinero a otro banco deberás indicar el número y código interbancario de la cuenta de ahorros de recepción de dinero, esta cuenta deberá tener la misma titularidad de la cuenta de ahorros en SURGIR.<br /> En caso de enviar la carta al buzón, colocar como asunto: Cancelación de Depósito a Plazo Fijo y/o Cuenta de Ahorros.
                      </p>

                      <p>
                        Para más información contactarse con su Ejecutivo de Cuenta.
                      </p>
      </div>
    },
    {
      title: '¿Dónde puedo solicitar más información para abrir mi cuenta?',
      body: <ul>
      <li>Solicita un <span>Depósito a Plazo Fijo</span> en nuestro <a target='_blank' href={LinkRoutes.formDepositFixed}>formulario</a> y nos comunicaremos contigo.</li>
      <li><span>Oficina Especial Compartida:</span> Cl. Amador Merino Reina 411, San Isidro.</li>
      {/* <li><span>Central telefónica:</span> <a href="tel:(01) 480 0123"
        rel="noopener noreferrer">(01) 480 0123.</a>
      </li> */}
    </ul>
    },
  ]

  const TABS_SAVING_ACCOUNT = [
    {
      title: 'Requisitos',
      body: (
        <div>
          <div className="tabs-savings-account__requeriments">
            <span>Persona Natural</span>
            <ul>
              <li>Presentar original y copia de tu Documento Oficial de Identidad (DOI).</li>
            </ul>
            <p>En caso actúe por medio de un representante o apoderado deberá proporcionarse:</p>
            <ul>
              <li>Vigencia de poder (no mayor a 30 días) del apoderado.</li>
              <li>Copia del Documento de Identidad del apoderado.</li>
            </ul>
          </div>

          <div className="tabs-savings-account__requeriments">
            <span>Persona Jurídica</span>
            <ul>
              <li>Ficha RUC actualizada.</li>
              <li>Copia del Testimonio de constitución de la Persona Jurídica u otro documento que acredite la creación de la sociedad.</li>
              <li>Copia Literal o Partida Electrónica de la Persona Jurídica o Vigencia de poder del apoderado o representante legal no mayor a 30 días u otro documento que acredite sus poderes.</li>
              <li>Copia del Documento de Identidad del apoderado o representante legal.</li>
              <li>Otros documentos de corresponder.</li>
            </ul>
            <p><span>Importante:</span> De acuerdo con el tipo de sociedad, el Ejecutivo de Cuenta le solicitará documentación adicional.</p>

          </div>
        </div>
      )
    },
    {
      title: 'Documentos',
      body: (
        <div className="tabs-savings-account__documents">
          <div>
            <SurgirDownloadIcon
              text={'Documentos Contractuales - Cartilla PN (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_DOCS_CONTRACTUALES_CARTILLA_PN_2025}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Documentos Contractuales - Cartilla PJ (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_CONTRACTUAL_CARTILLA_PJ}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Documentos Contractuales - Contrato (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_DOCS_CONTRACTUALES_CONTRATO}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Tarifario de tasas (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_TARIFARIO_TASAS}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Tarifario de comisiones (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_TARIFARIO_COMISIONES}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Fórmulas y ejemplos explicativos (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_FORMULAS_EJEMPLOS_EXPLICATIVOS}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Documento o sección de Beneficios, Riesgos y Condiciones del Producto (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_BENEFICIOS_RIESGOS_CONDICIONES}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Solicitud de Apertura (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_SOLICITUD_APERTURA}
              underline={true}
            />            
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Carta de Instrucción (PDF/560 KB)'}
              href={process.env.REACT_APP_CA_CARTA_INSTRUCCION}
              underline={true}
            />
          </div>
        </div>
      )
    },
    {
      title: 'Preguntas frecuentes',
      body: (
        <div className="tabs-savings-account__faq">
          <Accordion>
            <SurgirAccordion sections={frequentQuestionsPART1}/>
          {
            !faqVisible ? (

              <div className="section-information__tabs__link">
                <button className="section-information__tabs__link__body" onClick={() => setFaqVisible(true)} >
                  <h6 >Ver más preguntas</h6>
                  <span>
                    <img src="icons/safe/chevron-down-sm.svg" alt="cuenta de ahorros" />
                  </span>
                </button>
              </div>

            ) : (
                <div className='mt-2'>
                  <SurgirAccordion sections={frequentQuestionsPART2}/>

                  <div className="section-information__tabs__link">
                    <button className="section-information__tabs__link__body" onClick={() => setFaqVisible(false)} >
                      <h6 >Ver menos preguntas</h6>
                      <span>
                        <img src="icons/safe/chevron-up-sm.svg" alt="cuenta de ahorros" />
                      </span>
                    </button>

                  </div>

                </div>
            )
          }
          
        </Accordion>
      </div>
      )
    },
  ]

  const redirectCards = () => {
    window.location.href = '#cards-banner'
  };

  const redirectDpf = () => {
    window.open(LinkRoutes.dpf, '_blank')
  }

  const redirectDpff = () => {
    window.open(LinkRoutes.flexibleFixedDeposit, '_blank')
  }

  const mobileTab = useMediaQuery('(max-width: 767px)');
  

  return (
    <div>
      <SurgirBanner
        image={'banner-cuenta-ahorro.png'}
        title='Cuenta de Ahorros'
        description='Abre tu cuenta al solicitar un Depósito a Plazo Fijo.'
        showButton={true}
        textButton='Solicítalo ahora'
        imageFooter={'logo-santander-red.svg'}
        backgroundColor='#FFFFFF'
        showFooter={true}
        eventClickButton={redirectCards}

      />

      <div className="benefits">
        <Container>
            <div className='titleTablet2'>
              <SurgirPrincipalTitle 
                text={'Ahorra de forma segura y rentable en SURGIR'}
                alignText="center"
              />
            </div>
          <div className="benefits__body">

            <div className='benefits__body__hero'>
              <img alt="Cuenta de ahorros" src={Benefits} />
            </div>
            <div className="benefits__body__content">
              <div className='benefits__body__content__title'>
                <SurgirPrincipalTitle 
                  text={'Ahorra de forma segura y rentable en SURGIR'}
                  alignText="left"
                />
              </div>
              <p>
                Recibe en tu Cuenta de Ahorros SURGIR, el abono de los
                <strong> intereses ganados en soles o dólares </strong>
                por tu Depósito a Plazo Fijo.
              </p>
              <div className="benefits__body__content__list">
                <ul>
                  <li>
                    <img alt="Depósito a Plazo Fijo Flexible" src={'icons/savings-account/financing.svg'} />
                    <span>
                      Te pagamos intereses todos los días.
                    </span>
                  </li>
                  <li>
                    <img alt="Depósito a Plazo Fijo Flexible" src={'icons/savings-account/money-dollar.svg'} />
                    <span>
                      Sin monto mínimo de apertura.
                    </span>
                  </li>
                  <li>
                    <img alt="Depósito a Plazo Fijo Flexible" src={'icons/savings-account/no-payment.svg'} />
                    <span>
                      Sin costo de mantenimiento.
                    </span>
                  </li>
                  <li>
                    <img alt="Depósito a Plazo Fijo Flexible" src={'icons/savings-account/callback.svg'} />
                    <span>
                      Elige entre cuenta individual o mancomunada.
                    </span>
                  </li>
                </ul>
              </div>

              <div className="benefits__body__content__button">
                <SurgirButton
                  variant="danger"
                  radius="32px"
                  width={"130px"}
                  height={"46px"}
                  fontFamily={'"Str-Microtext-Bold", sans-serif'}
                  marginBottom='0px'
                  marginTop='16px'
                  onClick={redirectCards}
                >
                  ¡Lo quiero!
                </SurgirButton>
              </div>
            </div>
      
          </div>
        </Container>
      </div>
      

      <div className="carousel-savings-account">
        <div>
          <SurgirPrincipalTitle text="Descubre los beneficios que tenemos para ti" />

          <div>
            <SurgirCarousel
              items={benefitsSavingsAccount}
            />
          </div>
        </div>
      </div>

      <div>
        <SurgirSlideSantander/>
      </div>
      
      <div className='cards-savings-account' id='cards-banner'>
        <Container>
          <div className='cards-savings-account__title'>
            <SurgirPrincipalTitle text="Abre tu Depósito a Plazo Fijo con tu Cuenta de Ahorros" />
          </div>

          <div className='cards-savings-account__cards'>
            <div className='cards-savings-account__cards__card'>
              <h3 style={{height: '50px'}}>Depósito a Plazo Fijo</h3>
              <p>Disfruta de nuestras SÚPER TASAS este verano</p>
              <div className='cards-savings-account__cards__card__image'>
                <img alt="Alt DPF_ Sube la temperatura con nuestras súper tasas SURGIR ahorros inversión depósito a plazo fijo Card Producto" 
                  src={Card1} />
              </div>

              <div>
                <SurgirButton
                  variant="danger"
                  radius="32px"
                  width={"155px"}
                  height={"46px"}
                  marginBottom={"0px"}
                  marginTop={"0px"}
                  fontFamily={"'Str-Microtext-Bold', sans-serif"}
                  onClick={redirectDpf}
                >
                  Conócelo aquí
                </SurgirButton>
              </div>
            </div>
            <div className='cards-savings-account__cards__card'>
              <h3 style={{height: '50px'}}>Depósito a Plazo Fijo Flexible</h3>
              <p>
                Incrementa y retira tu capital manteniendo tu tasa de apertura.
              </p>
              <div className='cards-savings-account__cards__card__image'>
                <img 
                  alt="Alt DPFF_ Incrementa y retira tu capital sin afectar tu tasa SURGIR ahorros inversión depósito a plazo fijo flexible Card Producto" 
                  src={Card2} />
              </div>
              <div>
                <SurgirButton
                  variant="danger"
                  radius="32px"
                  width={"155px"}
                  height={"46px"}
                  marginBottom={"0px"}
                  marginTop={"0px"}
                  fontFamily={"'Str-Microtext-Bold', sans-serif"}
                  onClick={redirectDpff}
                >
                  Conócelo aquí
                </SurgirButton>
              </div>
            </div>

          </div>
        </Container>
      </div>

      <div className='tabs-savings-account' id='tabs'>
        <Container>
          <SurgirPrincipalTitle text={'Conoce más sobre tu Cuenta de Ahorros'} />

          {
            !mobileTab ? 
            <div style={{marginTop: 30}}>
              <SurgirTabs tabs={TABS_SAVING_ACCOUNT} />
            </div>
            : 
            <div className='container-acordion-responsive'>
              <SurgirAccordion 
                sections={TABS_SAVING_ACCOUNT} 
              />
            </div>
          }
        </Container>
      </div>

    </div>
  )
}

export default SavingsAccount